import axios from 'axios'

const auth = localStorage.getItem("token");
// const auth = "";

const api = axios.create({
    //baseURL: 'http://10.1.0.48:6969/api/v1',
   //baseURL: 'http://127.0.0.1:6969/api/v1',
    baseURL: 'https://api-reservation.jonasphoto.co.id/api/v1',
    //baseURL: 'http://117.102.68.238:6969/api/v1',
    // baseURL: 'https://api-reservation.jonasphoto.co.id/api/v1',
    timeout: 10000,
    headers: {
        'X-Custom-Header': 'foobar',
        'Authorization': 'Bearer ' + auth
    }
});

export default api
